import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2';
import { AlertType } from '../models/common.model';
import { LoaderService } from './loader.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private isCallingApiSubject = new BehaviorSubject<boolean>(false);
  public isCallingApi$ = this.isCallingApiSubject.asObservable();

  constructor(
    private router: Router,
    private loaderService: LoaderService
  ) { }

  setIsCallingApi(isCallingApi: boolean): void {
    this.isCallingApiSubject.next(isCallingApi);
  }

  handleHttpResponseError(error: HttpErrorResponse) {
    this.loaderService.loader.next(false);
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
      console.log(error.error.message);
      console.log(error.status);
    } else {
      if (error.status === 400){
        this.showNotification( AlertType.Error, error.error)
      } else if (error.status === 401){
        this.showNotification( AlertType.Error, "Something went wrong. Please login again!")
        this.signOut();
      } else if (error.status === 500){
        this.showNotification( AlertType.Error, error.statusText)
        this.signOut();
      }
    }
  }

  signOut() {
    localStorage.clear();
    this.router.navigate(['/']);
  }

  showNotification( type: SweetAlertIcon, message: string, title: string = "Attention please!", position: SweetAlertPosition = 'top-end', timer: number = 3000) {
    Swal.fire({
      title,
      icon: type,
      html: message,
      toast: true,
      timer: timer,
      position,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  showDialogueWithNoTimer(type: SweetAlertIcon, message: string, position: SweetAlertPosition = 'center') {
    Swal.fire({
      icon: type,
      html: message,
      toast: false,
      position,
      showConfirmButton: false,
      showCloseButton: true,
      backdrop: true,
      allowOutsideClick: false,
      width: 500
    });
  }

  showConfirmation(message: string, confirmButtonText: string = 'Yes', denyButtonText: string = 'No', title: string = '') {
    return Swal.fire({
      title: title,
      html: message,
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText,
      denyButtonText,
      confirmButtonColor: 'green'
    });
  }

  
}

